import conceptsNb from './concepts/automat-nb'
import { getDataByEnv } from '@/core/utils'

const customDataByEnv = {
  namespace: {
    production: '29c064b8-b5a4-4286-9f19-74e1021ee7e9',
    development: 'c5a84076-af99-4a3d-975e-1a919eb49829',
  },
}

export default {
  name: {
    nb: 'Automatiseringssystemer',
    nn: 'Automatiseringssystem',
  },
  slug: 'automatiseringsteknikk',
  namespace: getDataByEnv('namespace', customDataByEnv),
  analytics: {
    gtm: 'GTM-M293LX7',
  },
  assets: {
    logo: '/themes/automatiseringsteknikk/images/logo.svg',
    favicon: '/themes/automatiseringsteknikk/favicon.ico',
  },
  dbok: {
    nb: [
      {
        title: 'Automatiseringssystemer 1',
        link: 'https://reader.dbok.no/#/book/612ce5965fc1a8001211faf7',
      },
      {
        title: 'Automatiseringssystemer 2',
        link: 'https://reader.dbok.no/#/book/62d65986df1f7b0014ff6ad8',
      },
    ],
    nn: [
      {
        title: 'Automatiseringssystem 1',
        link: 'https://reader.dbok.no/#/book/6177fa4d3d72a1001237270f',
      },
      {
        title: 'Automatiseringssystem 2',
        link: 'https://reader.dbok.no/#/book/63578ccd8c3e920014ac5e25', 
      },
    ],
  },
  styles: [
    '/themes/automatiseringsteknikk/css/automatiseringsteknikk.css',
    '/common/icons/icons.css',
    'https://cdn.jsdelivr.net/npm/katex@0.13.2/dist/katex.min.css',
  ],
  welcomeMessage: {
    nb: '<h1>Velkommen til Automatiseringssystemer vg2 og vg3</h1><p>Læremiddelet er utgitt med støtte fra Utdanningsdirektoratet.</p>',
    nn: '<h1>Velkommen til Automatiseringssystemer vg2 og vg3</h1><p>Læremiddelet er gitt ut med støtte frå Utdanningsdirektoratet.</p>',
  },
  langs: [
    {
      code: 'nb',
      label: 'Bokmål',
    },
    {
      code: 'nn',
      label: 'Nynorsk',
    },
  ],
  concepts: {
    filters: {
      nb: conceptsNb,
    },
  },
  mainNav: {
    nb: [
      {
        title: 'Yrkesoppgaver',
        to: '/articles/yrkesoppgaver',
      },
      {
        title: 'Multimedia',
        to: '/media/multimedia',
      },
      {
        title: 'Fagbegrep',
        to: '/concepts/fagbegrep',
      },
      {
        title: 'Fagartikler',
        to: '/articles/fagartikler',
      },
      {
        title: 'Dokumentasjon',
        to: '/files/dokumentasjon',
      },
    ],
    nn: [
      {
        title: 'Yrkesoppgåver',
        to: '/articles/yrkesoppgaver',
      },
      {
        title: 'Multimedium',
        to: '/media/multimedia',
      },
      {
        title: 'Fagomgrep',
        to: '/concepts/fagbegrep',
      },
      {
        title: 'Fagartiklar',
        to: '/articles/fagartikler',
      },
      {
        title: 'Dokumentasjon',
        to: '/files/dokumentasjon',
      },
    ],
  },
}
