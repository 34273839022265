export default {
  conceptsFilters: [
    {
      title: 'Fagområde',
      items: [
        {
          title: 'Dynamisk nøyaktighet',
          id: '5fb371db7f3acc0018e87a30'
        },
        {
          title: 'Elektroteknikk',
          id: '5fb371eacbb91b0019f44c4e'
        },
        {
          title: 'EMC og EMI',
          id: '5fb371f9cbb91b0019f44c4f'
        },
        {
          title: 'Fluidmekanikk',
          id: '5fb3720a7f3acc0018e87a31'
        },
        {
          title: 'Grunnleggende fysikk',
          id: '5fa54ca0188619001195c9e7'
        },
        {
          title: 'Instrumentering',
          id: '5fb37229cbb91b0019f44c51'
        },
        {
          title: 'Kalibrering og justering',
          id: '5fb3722d7f3acc0018e87a33'
        },
        {
          title: 'Kraft',
          id: '5fb37236cbb91b0019f44c52'
        },
        {
          title: 'Måle- og signalomformere',
          id: '5fb372487f3acc0018e87a34'
        },
        {
          title: 'Måleområder og måleomfang',
          id: '5fb375787f3acc0018e87a35'
        },
        {
          title: 'Temperatur',
          id: '5fb37584cbb91b0019f44c53'
        },
        {
          title: 'Trykk',
          id: '5fb375907f3acc0018e87a36'
        },
        {
          title: 'Signaler',
          id: '5fb3759ccbb91b0019f44c54'
        }
      ]
    }
  ]
}